<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Actualizar contrato</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <br />

          <v-row>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" lg="4">
              <v-select v-model="id_customer" :rules="customerRules" :items="customers" item-text="legal_name"
                item-value="id" prepend-icon="mdi-view-list" label="Seleccionar empresa *" persistent-hint></v-select>
              <v-select v-model="id_service" :rules="serviceRules" v-on:change="getPlansOnChange" :items="services"
                item-text="name" item-value="id" prepend-icon="mdi-text-box-outline" label="Seleccionar Servicio *"
                persistent-hint></v-select>
              <v-select v-model="id_plan" :rules="planRules" :items="plans" item-text="name" item-value="id"
                prepend-icon="mdi-view-list" label="Seleccionar plan *" persistent-hint></v-select>
              <v-select v-model="id_billing_periods" :rules="billingRules" :items="billingPeriods"
                item-text="billing_period" item-value="id" prepend-icon="mdi-view-list"
                label="Seleccionar día de facturación *" persistent-hint></v-select>
            </v-col>

            <v-col cols="12" lg="4"> </v-col>
          </v-row>
          <br /><br />

          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="8"></v-col>
            <v-col cols="12" lg="4">
              <v-btn color="primary" outlined class="mr-4" @click="cancel" outline>
                <v-icon>mdi-close-circle</v-icon> Cancelar
              </v-btn>
              <v-btn color="primary" outlined @click="update" outline>
                <v-icon>mdi-sync-circle</v-icon> Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    customers: [],
    plans: [],
    billingPeriods: [],
    services: [],

    id_customer: "",
    id_plan: "",
    id_billing_periods: "",
    id_service: "",

    customer: "",
    plan: "",
    billing_period: "",

    customerRules: [
      v => !!v || 'El cliente es requerido'
    ],
    serviceRules: [
      v => !!v || 'El servicio es requerido'
    ],
    planRules: [
      v => !!v || 'El plan es requerido'
    ],
    billingRules: [
      v => !!v || 'El período es requerido'
    ],
  }),

  mounted() {
    this.getCustomers();
    this.getPlans();
    this.getBillingPeriods();
    this.getServices();
    this.chargeData();

  },
  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "contracts" }), 10);
    },
    getServices() {
      axios
        .get("/services")
        .then((response) => {
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener servicios contrato"
          );
          setTimeout(() => this.$router.push({ name: "contracts" }), 4000);
        });
    },
    getIdService(id_plan) {
      //  this.getServices();
      let plan = this.plans.find((plan) => plan.id === id_plan);
      return plan.id_service;
    },
    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/get-contract-by-id", request)
        .then((response) => {
          console.log(response.data);
          this.id_customer = response.data.id_customer;
          this.id_plan = response.data.id_plan;
          this.id_billing_periods = response.data.id_billing_periods;
          this.id_service = this.getIdService(this.id_plan);
          this.plans = this.plans.find(
            (plan) => plan.id_service === this.id_service);
          this.getPlansOnChange();
          this.id_plan=response.data.id_plan;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar los contratos"
          );
        });
    },

    update() {
      console.log("actualizando plan");
      let request = {
        id: this.$route.params.id,
        id_customer: this.id_customer,
        id_plan: this.id_plan,
        id_billing_periods: this.id_billing_periods,
      };
      console.log("imprime request");
      console.log(request);
      if (
        this.id_customer != "" &&
        this.id_plan != "" &&
        this.billing_period != ""
      ) {
        axios
          .post("/update-contract", request)
          .then((response) => {
            this.displayNotification("success", "Éxito", "Se actualizó correctamente el contrato");
            console.log(response);
            this.$router.push({ name: "contracts" });
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "No se pudo actualizar el contrato"
            );
            //setTimeout(() => this.$router.push({ name: "plan" }), 4000);
          });
      } else {
        this.displayNotification(
          "error",
          "Error",
          "Debe seleccionar un servicio"
        );
      }
    },
    getCustomers() {
      axios
        .get("/customers")
        .then((response) => {
          console.log("Clientes");
          console.log(response.data);
          this.customers = response.data;
          this.customer = this.customers.find((c) => c.id === this.id_customer);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener clientes"
          );
        });
    },

    getPlansOnChange() {
      this.id_plan = "";
      this.plans = [];
      if (this.id_service != "" && parseInt(this.id_service) > 0) {
        let request = {
          id_service: this.id_service,
        };
        axios
          .post("/plan-by-service", request)
          .then((response) => {
            console.log(response.data);
            this.plans = response.data;
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification("error", "Error", "Error al obtener planes");
          });
      }
    },
    getPlans() {
      axios
        .get("/plans")
        .then((response) => {
          console.log("Planes");
          console.log(response.data);
          this.plans = response.data;

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },

    getBillingPeriods() {
      axios
        .get("/billing-periods")
        .then((response) => {
          console.log("Periodo de facturacion");
          console.log(response.data);
          this.billingPeriods = response.data;
          this.billing_period = this.billingPeriods.find(
            (bp) => bp.id === this.id_billing_period
          );
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener días de facturacion"
          );
        });
    },

    getNameService(id_service) {
      //  this.getServices();
      let serviceName = this.services.find(
        (service) => service.id === id_service
      );

      return serviceName.name;
    },
    getNamePlan(id_plan) {
      //  this.getServices();
      let plan = this.plans.find((plan) => plan.id === id_plan);

      return plan.name;
    },

    getNameCustomer(id_customer) {
      //  this.getServices();
      let customer = this.customers.find(
        (customer) => customer.id === id_customer
      );

      return customer.legal_name;
    },
  },
};
</script>